import {
  SubscriptionLicenceType,
  SubscriptionLicenceRequest,
  SubscriptionLicenceForm,
  SubscriptionLicence,
  SubscriptionLicenceStatus,
  LicenseFunctionality,
  NetsuiteArcResult,
  SubscriptionDuration,
} from "./models";
import {
  SUBSCRIBE_ALL_REQUESTS_VIEW_FILTER,
  INSERT_REQUEST,
  SUBSCRIBE_REQUEST_VIEW_FILTER_COUNT,
  SUBSCRIBE_ALL_SUBSCRIPTIONS_LICENSES,
  SUBSCRIBE_SUBSCRIPTION_LICENCES_VIEW_FILTER_COUNT,
  SHARE_CODES,
  ADD_CODE,
  GET_ALL_LICENSE_FUNCTIONALITIES,
  VERIFY_ARC,
  GET_ENDING_SUBSCRIPTION,
  UPDATE_ENDING_DATE_SUBSCRIPTION,
  GET_ALL_ARC,
  CHECK_ARC_USAGE,
} from "./queries";
import { useMutation, useQuery, useSubscription } from "@vue/apollo-composable";
import dayjs from "@/plugins/dayjs";
import { computed, ref, watch } from "vue";
import { debouncedRef } from "@vueuse/core";
import { formatDateHasura } from "@/helpers/datetime";
import { VehicleFunctionnality } from "../vehicle-service/models";
import { useAuthStore } from "@/store/auth";
import { useCurrentApolloClient } from "@/plugins/apollo";
import { CSV_SEPARATOR } from "@/helpers/export-utils";
import { getMonthsFromSubscriptionDuration } from "@/helpers/subscription-duration";

export function insertSubscriptionLicenceRequest() {
  const { onDone, onError, mutate, loading } = useMutation(INSERT_REQUEST);

  function insert(request: SubscriptionLicenceForm[]) {
    mutate({
      request: {
        licenses: {
          data: request
            .filter((el) => el.type === SubscriptionLicenceType.LICENCE)
            .flatMap((item) => Array(item.quantity).fill(item))
            .map((el: SubscriptionLicenceForm) => {
              return {
                licenses_functionalities_id: el.subtype,
              };
            }),
        },
        subscriptions: {
          data: request
            .filter((el) => el.type === SubscriptionLicenceType.SUBSCRIPTION)
            .flatMap((item) => Array(item.quantity).fill(item))
            .map((el: SubscriptionLicenceForm) => {
              return {
                arc: el.arc,
                duration: el.duration,
                user_email: el.email,
                functionalities: {
                  data: [
                    {
                      functionality_id: el.subtype,
                    },
                  ],
                },
              };
            }),
        },
      },
    });
  }
  return {
    onDone,
    onError,
    loading,
    insert,
  };
}

export function getAllRequests() {
  const textFilter = ref("");
  const arcFilter = ref(null);
  const columnOrders = ref<any>({ created_at: "desc" });

  const rangeFilter = ref<string[]>([]);
  const typeFilter = ref<SubscriptionLicenceType[]>([]);

  const limit = ref(15);
  const page = ref(1);
  const requests = ref<SubscriptionLicenceRequest[]>([]);

  const whereText = computed(() => (textFilter.value !== "" ? { _or: [{ id_as_text: { _ilike: `%${textFilter.value}%` } }, { subscriptions: { arc: { _ilike: `%${textFilter.value}%` } } }] } : null));
  const whereArc = computed(() => (arcFilter.value !== null ? { subscriptions: { arc: { _ilike: `%${arcFilter.value}%` } } } : null));
  const whereType = computed(() => {
    if (!typeFilter.value?.length) {
      return null;
    }
    const orConditions = [];
    if (typeFilter.value.includes(SubscriptionLicenceType.SUBSCRIPTION)) {
      orConditions.push({ subscription_count: { _gt: 0 } });
    }
    if (typeFilter.value.includes(SubscriptionLicenceType.LICENCE)) {
      orConditions.push({ licence_count: { _gt: 0 } });
    }
    return orConditions.length ? { _or: orConditions } : null;
  });

  const whereCreatedAt = computed(() => {
    return rangeFilter.value.length === 2
      ? {
        created_at: {
          _gte: formatDateHasura(dayjs(rangeFilter.value[0])),
          _lte: formatDateHasura(dayjs(rangeFilter.value[1])),
        },
      }
      : null;
  });

  const allFilters = computed(() => [whereText.value, whereType.value,whereArc.value, whereCreatedAt.value].filter(Boolean));

  const where = computed(() =>
    allFilters.value.length !== 0
      ? {
        _and: allFilters.value,
      }
      : {}
  );

  watch(where, () => {
    if (page.value !== 1) {
      page.value = 1;
    }
  });

  const offset = computed(() => (page.value - 1) * limit.value);

  const { loading: fetchLoading, onResult } = useSubscription(
    SUBSCRIBE_ALL_REQUESTS_VIEW_FILTER,
    debouncedRef(
      ref({
        offset,
        limit,
        where: where,
        order_by: columnOrders,
      })
    )
  );

  onResult((res) => {
    requests.value = SubscriptionLicenceRequest.mapAll(res.data?.request_view_for_filter);
  });


  const { result } = useQuery(GET_ALL_ARC);
  const allArc = computed<string[]>(() => {
    if (result.value?.subscription_code) {
      return Array.from(new Set(result.value.subscription_code.map((v: { arc: string }) => v.arc)));
    }
    return [];
  });



  const { result: totalResult, loading: totalLoading } = useQuery(SUBSCRIBE_REQUEST_VIEW_FILTER_COUNT, {
    where: debouncedRef(where),
  });
  const total = computed(() => totalResult.value?.request_view_for_filter_aggregate?.aggregate.count);

  const loading = computed(() => fetchLoading.value || totalLoading.value);

  return {
    loading,
    columnOrders,
    requests,
    textFilter,
    total,
    limit,
    page,
    allArc,
    typeFilter,
    rangeFilter,
    arcFilter,
  };
}

export function getAllSubscriptionsLicenses() {
  const auth = useAuthStore();

  const textFilter = ref("");
  const columnOrders = ref<any>();
  const statusFilter = ref<SubscriptionLicenceStatus>();
  const subtypeFilter = ref<VehicleFunctionnality>();
  const vehicleFilter = ref<string>();
  const companyFilter = ref<string>();
  const startRangeFilter = ref<string[]>([]);
  const endRangeFilter = ref<string[]>([]);
  const typeFilter = ref<SubscriptionLicenceType>();

  const limit = ref(15);
  const page = ref(1);
  const result = ref<SubscriptionLicence[]>([]);

  if (!(auth.isAppAdmin() || auth.isLicenceManager() || auth.isAppAdminViewer())) {
    typeFilter.value = SubscriptionLicenceType.SUBSCRIPTION;
  }

  const whereText = computed(() => (textFilter.value !== "" ? { _or: [{ code_as_text: { _ilike: `%${textFilter.value}%` } }, { product_sn: { _ilike: `%${textFilter.value}%` } }] } : null));
  const whereType = computed(() => {
    if (!typeFilter.value) {
      return null;
    } else if (typeFilter.value === SubscriptionLicenceType.SUBSCRIPTION) {
      return { subscription_code: { _is_null: false } };
    } else {
      return { licence_code: { _is_null: false } };
    }
  });

  const whereStart = computed(() => {
    return startRangeFilter.value.length === 2
      ? {
        start_date: {
          _gte: formatDateHasura(dayjs(startRangeFilter.value[0])),
          _lte: formatDateHasura(dayjs(startRangeFilter.value[1])),
        },
      }
      : null;
  });

  const whereEnd = computed(() => {
    return endRangeFilter.value.length === 2
      ? {
        end_date: {
          _gte: formatDateHasura(dayjs(endRangeFilter.value[0])),
          _lte: formatDateHasura(dayjs(endRangeFilter.value[1])),
        },
      }
      : null;
  });

  const whereSubtype = computed(() => {
    if (typeFilter.value === SubscriptionLicenceType.SUBSCRIPTION) {
      return subtypeFilter.value ? { subscription: { functionalities: { functionality_id: { _eq: subtypeFilter.value } } } } : null;
    } else if (typeFilter.value === SubscriptionLicenceType.LICENCE) {
      return subtypeFilter.value ? { license: { functionality: { id: { _eq: subtypeFilter.value } } } } : null;
    }
    return null;
  });

  const whereVehicle = computed(() => {
    if (vehicleFilter.value) {
      return { vehicle_id: { _eq: vehicleFilter.value } };
    }
    return null;
  });

  const whereStatus = computed(() => {
    if (!statusFilter.value) {
      return null;
    }

    const now = dayjs();
    if (statusFilter.value === SubscriptionLicenceStatus.WAITING_FOR_ASSOCIATION) {
      return {
        _or: [
          {
            _and: [{ subscription_code: { _is_null: false } }, { start_date: { _is_null: true } }],
          },
          {
            _and: [{ licence_code: { _is_null: false } }, { product_sn: { _is_null: true } }],
          },
        ],
      };
    } else if (statusFilter.value === SubscriptionLicenceStatus.EXPIRED) {
      return {
        _and: [{ subscription_code: { _is_null: false } }, { end_date: { _lt: formatDateHasura(now) } }],
      };
    } else if (statusFilter.value === SubscriptionLicenceStatus.ACTIVATED) {
      return {
        _or: [
          {
            _and: [{ licence_code: { _is_null: false } }, { product_sn: { _is_null: false } }],
          },
          {
            _and: [{ subscription_code: { _is_null: false } }, { start_date: { _lte: formatDateHasura(now) } }, { end_date: { _gte: formatDateHasura(now) } }],
          },
        ],
      };
    }
  });

  const whereCompany = computed(() => {
    return companyFilter.value ? { _or: [{ subscription: { company_id: { _eq: companyFilter.value } } }, { license: { product: { company_id: { _eq: companyFilter.value } } } }] } : null;
  });

  const allFilters = computed(() =>
    [whereText.value, whereType.value, whereStart.value, whereStatus.value, whereEnd.value, whereSubtype.value, whereCompany.value, whereVehicle.value].filter(Boolean)
  );

  const where = computed(() =>
    allFilters.value.length !== 0
      ? {
        _and: allFilters.value,
      }
      : {}
  );

  watch(where, () => {
    if (page.value !== 1) {
      page.value = 1;
    }
  });

  const offset = computed(() => (page.value - 1) * limit.value);

  const { loading: fetchLoading, onResult } = useSubscription(
    SUBSCRIBE_ALL_SUBSCRIPTIONS_LICENSES,
    debouncedRef(
      ref({
        offset,
        limit,
        where: where,
        order_by: columnOrders,
      })
    )
  );

  onResult((res) => {
    result.value = SubscriptionLicence.mapAll(res.data?.subscription_licence_view);
  });

  const { result: totalResult } = useQuery(SUBSCRIBE_SUBSCRIPTION_LICENCES_VIEW_FILTER_COUNT, {
    where: debouncedRef(where),
  });
  const total = computed(() => totalResult.value?.subscription_licence_view_aggregate?.aggregate.count);

  const loading = computed(() => fetchLoading.value);

  return {
    loading,
    columnOrders,
    result,
    textFilter,
    total,
    limit,
    page,
    typeFilter,
    statusFilter,
    startRangeFilter,
    endRangeFilter,
    subtypeFilter,
    vehicleFilter,
    companyFilter,
  };
}

export function exportToCsv(subLicenses: SubscriptionLicence[]) {
  const auth = useAuthStore();

  const headers = [(auth.isAppAdmin() || auth.isAppAdminViewer()) ? "code" : "subscription code", "type", "subscription/license", "status", "blaxtair serial number", "vehicle", "start_date", "end_date"].join(CSV_SEPARATOR);

  const lines = subLicenses.map((report) => {
    return [
      report.code,
      report.subtype,
      report.subscriptionCode ? "subscription" : "license",
      report.status,
      report?.product?.serialNumber,
      report?.vehicle?.name,
      report.startDate?.toISOString(),
      report.endDate?.toISOString(),
    ].join(CSV_SEPARATOR);
  });

  const csvContent = "data:text/csv;charset=utf-8," + [headers, ...lines].join("\n");

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", (auth.isAppAdmin() || auth.isAppAdminViewer()) ? "subscriptions-licenses.csv" : "subscriptions.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function shareSubscriptionsLicenses() {
  const { onDone, onError, mutate, loading } = useMutation(SHARE_CODES);

  function insert(subscriptionsLicenses: SubscriptionLicence[], userIds: string[]) {
    mutate({
      userSubscriptions: subscriptionsLicenses
        .filter((el) => el.subscriptionCode)
        .flatMap((el) => {
          return userIds.map((userId) => ({ user_id: userId, subscription_code: el.code }));
        }),
      userLicenses: subscriptionsLicenses
        .filter((el) => el.licenseCode)
        .flatMap((el) => {
          return userIds.map((userId) => ({ user_id: userId, license_code: el.code }));
        }),
    });
  }

  return {
    onDone,
    onError,
    loading,
    insert,
  };
}

export function addCode() {
  const { onDone, onError, mutate, loading } = useMutation(ADD_CODE);

  function insert(code: string) {
    mutate({
      code,
    });
  }

  return {
    onDone,
    onError,
    loading,
    insert,
  };
}

export function getAllLicenseFunctionalities() {
  const { result, loading } = useQuery(GET_ALL_LICENSE_FUNCTIONALITIES);

  const licenseFunctionalities = computed<LicenseFunctionality[]>(() => {
    if (result.value) {
      return LicenseFunctionality.mapAll(result.value?.licenses_functionalities);
    }
    return [];
  });

  return {
    loading,
    licenseFunctionalities,
  };
}

export async function verifyArc(arc: string): Promise<NetsuiteArcResult> {
  const res = await useCurrentApolloClient().query({
    query: VERIFY_ARC,
    variables: {
      arc,
    },
  });
  return NetsuiteArcResult.map(res?.data?.is_valid_arc);
}

export async function checkArcUsage(arc: string): Promise<number> {
  const res = await useCurrentApolloClient().query({
    query: CHECK_ARC_USAGE,
    variables: {
      arc,
    },
  });
  return (res?.data?.subscription_code_aggregate?.aggregate?.count || 0);
}

export async function getEndingSubscriptionCode(): Promise<string[]> {
  const now = dayjs();

  const res = await useCurrentApolloClient().query({
    query: GET_ENDING_SUBSCRIPTION,
    variables: {
      isLowerThan: now.add(30, "days").toISOString(),
      now: now.toISOString(),
    },
  });
  if (res.errors) {
    throw Error(res.errors[0].message);
  }

  return (res?.data?.subscription_code || []).map((el) => el.code);
}

export async function updateEndingDateSubscriptionCode(element: SubscriptionLicence, value: SubscriptionDuration) {
  const monthsFromDuration = getMonthsFromSubscriptionDuration(value);
  const newEndDate = dayjs(element.endDate).add(monthsFromDuration, "months").toDate();

  const res = await useCurrentApolloClient().mutate({
    mutation: UPDATE_ENDING_DATE_SUBSCRIPTION,
    variables: {
      code: element.code,
      end_date: newEndDate,
      duration: value,
    },
  });
  if (res.errors) {
    throw Error(res.errors[0].message);
  }
  return res?.data?.update_subscription_code_by_pk;
}
