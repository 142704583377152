import { gql } from "@apollo/client/core";

export const INSERT_REQUEST = gql`
  mutation InsertSubscriptionLicenceRequest($request: subscription_licence_request_insert_input = {}) {
    insert_subscription_licence_request_one(object: $request) {
      id
      created_at
      arc
      licenses {
        code
        licenses_functionalities_id
        request_id
        serial_number_tablet
      }
      subscriptions {
        code
        company_id
        end_date
        product_id
        request_id
        start_date
        user_email
        vehicule_id
        functionalities {
          functionality {
            id
            key
          }
        }
      }
    }
  }
`;

export const SUBSCRIBE_ALL_REQUESTS_VIEW_FILTER = gql`
  subscription GetAllRequestsViewFilter($limit: Int = null, $offset: Int = null, $where: request_view_for_filter_bool_exp = {}, $order_by: [request_view_for_filter_order_by!] = {}) {
    request_view_for_filter(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      id
      created_at
      subscription_count
      licence_count
      id_as_text
      licenses {
        code
        functionality {
          id
          key
          title
        }
      }
      subscriptions {
        code
        arc
        functionalities {
          functionality {
            id
            key
          }
        }
      }
    }
  }
`;

export const SUBSCRIBE_REQUEST_VIEW_FILTER_COUNT = gql`
  subscription FetchRequestsFilterCount($where: request_view_for_filter_bool_exp = {}, $order_by: [request_view_for_filter_order_by!] = {}, $limit: Int = null, $offset: Int = null) {
    request_view_for_filter_aggregate(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
      aggregate {
        count
      }
    }
  }
`;

export const SUBSCRIBE_ALL_SUBSCRIPTIONS_LICENSES = gql`
  subscription GetSubscriptionsLicenses($where: subscription_licence_view_bool_exp = {}, $offset: Int = null, $limit: Int = null, $order_by: [subscription_licence_view_order_by!] = {}) {
    subscription_licence_view(order_by: $order_by, where: $where, offset: $offset, limit: $limit) {
      licence_code
      subscription_code
      code_as_text
      license {
        code
        functionality {
          id
          key
        }
      }
      subscription {
        code
        duration
        company {
          uuid
          name
        }
        functionalities {
          functionality {
            id
            key
          }
        }
      }
      end_date
      company_id
      licence_functionality
      product_id
      product_name
      product_sn
      request_id
      start_date
      user_email
      vehicle_id
      vehicle_name
    }
  }
`;

export const SUBSCRIBE_SUBSCRIPTION_LICENCES_VIEW_FILTER_COUNT = gql`
  subscription FetchRequestsFilterCount($where: subscription_licence_view_bool_exp = {}) {
    subscription_licence_view_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const SHARE_CODES = gql`
  mutation ShareCodes($userSubscriptions: [user_has_subscription_code_insert_input!] = {}, $userLicenses: [user_has_license_insert_input!] = {}) {
    insert_user_has_subscription_code(on_conflict: { constraint: user_has_subscription_code_pkey }, objects: $userSubscriptions) {
      affected_rows
    }
    insert_user_has_license(objects: $userLicenses, on_conflict: { constraint: user_has_license_pkey }) {
      affected_rows
    }
  }
`;

export const ADD_CODE = gql`
  mutation AddCode($code: String!) {
    handle_request_code(code: $code)
  }
`;

export const GET_ALL_LICENSE_FUNCTIONALITIES = gql`
  query GetLicenseFunctionalities {
    licenses_functionalities {
      id
      key
    }
  }
`;

export const VERIFY_ARC = gql`
  query verifyArc($arc: String!) {
    is_valid_arc(arc: $arc) {
      is_valid
      client
    }
  }
`;

export const CHECK_ARC_USAGE = gql`
  query CheckArcUsage($arc: String!) {
    subscription_code_aggregate(where: { arc: { _eq: $arc } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_ARC = gql`
  query GetAllCompaniesArc{
    subscription_code(where: { arc: { _is_null: false } }) {
      arc
    }
  }
`;

export const GET_ENDING_SUBSCRIPTION = gql`
  query GetEndingSubscription($isLowerThan: timestamptz!, $now: timestamptz!) {
    subscription_code(where: { _and: [{ end_date: { _lte: $isLowerThan } }, { end_date: { _gte: $now } }] }) {
      code
    }
  }
`;

export const UPDATE_ENDING_DATE_SUBSCRIPTION = gql`
  mutation UpdateEndingDateSubscriptionCode($code: uuid!, $end_date: timestamptz!, $duration: String!) {
  update_subscription_code_by_pk(pk_columns: {code: $code}, _set: {end_date: $end_date, duration: $duration}) {
    code
  }
}
`;
