import dayjs, { Dayjs } from "dayjs";
import { i18n } from "@/plugins/i18n";

export function formatDateHasura(date: Date | Dayjs) {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ");
}

export function formatDateDisplay(date: Date | string) {
  const format = i18n.global.te("common.date-format") ? i18n.global.t("common.date-format") : "MM/DD/YYYY";
  return dayjs(date).format(format);
}

export function formatDateTimeDisplay(date: Date | string) {
  const format = i18n.global.te("common.date-time-format") ? i18n.global.t("common.date-time-format") : "MM/DD/YYYY hh:mm";
  return dayjs(date).format(format);
}

export function formatDateWithoutTimezone(date: string): string {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss");
}
export function getLastNumberDays(days: number) {
  const today = new Date();
  today.setHours(23, 59, 59, 999);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - days);
  sevenDaysAgo.setHours(23, 59, 59, 999);
  return {
    today,
    sevenDaysAgo,
  };
}

export function formatDateTimeRangeDisplay(dates: string) {
  if (!dates) {
    return;
  }
  return `${formatDateTimeDisplay(dates.split(" - ")[0])} - ${formatDateTimeDisplay(dates.split(" - ")[1])}`;
}



export function formatDateRangeDisplay(dates: string) {
  if (!dates) {
    return;
  }
  return `${formatDateDisplay(dates.split(" - ")[0])} - ${formatDateDisplay(dates.split(" - ")[1])}`;
}

