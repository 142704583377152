import { VehicleGroupTag } from "./model";

export function mapVehicleGroupTag(data: any): VehicleGroupTag {
  if (!data) return null;
  return {
    uuid: data?.uuid,
    label: data?.label,
    companyUuid: data?.company_uuid,
  };
}

export function mapVehicleGroupTags(data: any): VehicleGroupTag[] {
  if (!data) {
    return [];
  }
  return data.map(mapVehicleGroupTag);
}
